<template>
  <div class="contact-right" :style="'top:' + valTop + 'px'">
    <img src="@/assets/images/contact/1.png" @click="contactWeb" />
    <img
      src="@/assets/images/contact/2.png"
      @click="$router.push('/game/realbet')"
    />
    <img src="@/assets/images/contact/3.png" @click="opengameurl" />
    <img
      src="@/assets/images/contact/4.png"
      @click="$router.push('/help?type=kh')"
    />
    <div class="close" @click="close">
      <span></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      valTop: 120,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.initInfo);
  },
  methods: {
    initInfo() {
      const domTop = document.documentElement.scrollTop;
      this.valTop = 120 + domTop;
    },
    close() {
      this.$emit("update:visible", false);
    },
    // 打开视讯游戏
    opengameurl() {
      if (!this.isLogin) {
        this.$confirm(
          this.$t("components.Header.877183-25"),
          this.$t("components.Header.877183-26"),
          {
            confirmButtonText: this.$t("components.Header.877183-27"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {});
        return;
      }
      this.$loading.show();
      const params = {
        platType: "ag",
        gameType: 1,
        devices: 0,
      };
      this.$Api.getGameUrl(params).then((res) => {
        if (res) {
          this.$loading.hide();
          const url = JSON.parse(res.data);
          window.open(url.url, "_blank");
        }
      });
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.initInfo);
  },
};
</script>
<style lang="scss" scoped>
.contact-right {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: all 0.5s;
  img {
    position: relative;
    z-index: 2;
  }
  .close {
    width: 40px;
    height: 40px;
    background: #680407;
    border-radius: 50%;
    position: relative;
    transform: translateY(-20px);
    cursor: pointer;
    span {
      width: 30%;
      height: 30%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -5%);
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        width: 100%;
        height: 2px;
        background: yellow;
        transform: rotate(45deg);
        border-radius: 4px;
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
</style>
